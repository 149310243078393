import {inject} from 'aurelia-framework';
import { FeedbackService } from '../../shared/services/feedbackservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import * as tinymce from 'tinymce';
import {Router} from 'aurelia-router';
import { App } from '../../app';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../shared/services/leavemanagementservice";
import {SavedDataComponent} from "./fetchsaveemployeefeedback/saveddatacomponent";
import { JwtService } from '../../shared/services/jwtservice';
import { config } from '../../shared/services/config';
import { AttendanceService } from '../../shared/services/attendanceservice';
import { Viewfeedback } from './viewfeedback/viewfeedback';
import {LoginService} from "../../shared/services/loginservice";
import { Reopenmodal } from './reopenmodal/reopenmodal';
import { constants } from '../../shared/services/constants';
import { DashboardService } from '../../shared/services/dashboardservice';


@inject(FeedbackService, ValidationControllerFactory,DialogService ,LeavemanagementService,JwtService,AttendanceService, Router, App,LoginService,JwtService,DashboardService)
export class EmployeeFeedbackComponent {
    options = []; //sendto array
    selected = {}; //selected employees in send to
    searchText = ''; //search text in send to dropdown
    selectAll = true; //select all in send to dropdown
    departments = []; // departments array
    errors = null;
    selectedSentoEmployees = [];
    SelectedSendtoEmployeesIDs = [];
    EmployeeListIDs = [];
    showPopup = false;
    pageSize = config.grid_PazeSize;
    topicsPageSize = config.grid_PazeSize;
    showDropdown = false;
    selectedEmployees = '';
    showEmpDropdown = false;
    removeButton = true;
    updateMessages = false;

    selectedOption = '1';
    employeeOrDepartment = '1';
    employeeType;

    selectedOptionLabel = "Feedback";
    FeedbackLabel = "Feedback";
    GrievanceLabel = "Grievance";
    feedbackValue = "";
    grievanceValue = "";
    formType = '';
    emails = '';
    emailsErrors = null;
    selectedFormType = 1;

    editor;
    commentsMessage;

    fileMessage;
    gridfeedbackData = [];
    siteMapId=constants.myfeedbackSiteMapID;

    isFormSubmitted = false;
    siteMapId=constants.myfeedbackSiteMapID;


    //pageSize = 10;

    constructor(feedbackservice, controllerFactory, dialogService,LeavemanagementService,JwtService,AttendanceService, router, App,loginservice,jwtService,DashboardService) {
        this.feedbackservice = feedbackservice;
        this.jwtService = JwtService;
        this.attendanceService = AttendanceService;
        this.controller = controllerFactory.createForCurrentScope();
        this.searchQuery = '';
        this.dropdownOptions = [];
        this.selectedEmployees = [];
        this.selectedSentoEmployees = [];
        this.selectedEmployee = null;
        this.filterEmpOption = '';
        this.dialogService = dialogService;
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.app = App;
        this.loginservice = loginservice;
        this.jwtService = jwtService;
        this.dashboardService = DashboardService;

        this.EmpId = this.jwtService.getEmployeeId();
        document.addEventListener('click', this.handleClickOutsideDropdown);
        ValidationRules
            .ensure('title').required().withMessage("Subject is required")
            .maxLength(100)
            .withMessage('Subject should be less than 100 characters.')
            // .ensure('selectedOptions').required().withMessage("Send to filed is required")
            .ensure('emails')
            .satisfies((value) => {
                if (value && value.trim() !== '') {
                    const emails = value.split(',');
                    const emailRegex = /^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/;
                    const invalidEmails = [];

                    for (const email of emails) {
                        const trimmedEmail = email.trim();
                        if (!emailRegex.test(trimmedEmail)) {
                            invalidEmails.push(trimmedEmail);
                        }
                    }
                    if (invalidEmails.length > 0 || (emails.length > 1 && value.indexOf(',') === -1)) {
                        this.emailsErrors = ['Invalid email address and Email(s) should be separated by commas'];
                        return false;
                    }
                }
                this.emailsErrors = [];
                return true;
            }).withMessage('Invalid email address(es) and Email(s) should be separated by commas')

            .on(this);
    }

    monthMatcher = (a,b) => {
      return a.key === b.key
    };
  
    yearMatcher = (a,b) => {
      return a.Year === b.year
    }

    getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.organization');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
        const sidebarLink = document.querySelector('.sidebar ul li.organization .sidebar-sub .myfeedback a');
        if (sidebarLink) {
          sidebarLink.classList.add('active-link');
        }
        return routeURL;
      }
      return '';
    }

    attached() {
        this.getCurrentRouteURL();
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        this.addToRecentItems();
        tinymce.init({
            selector: '#comments',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            setup: editor => {
                editor.on('init', () => {
                    this.editor = editor;
                    this.editorContent = '';
                });
                editor.on('change', () => {
                    this.editorContent = editor.getContent();
                    this.commentsMessage = null;
                });
            },
        });
        const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png', 'application/msword', 'application/pdf'];

        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage = "Only JPEG, PNG, PDF and DOC files are allowed.";
            } else {
                this.fileMessage = null;
            }
        }, {
            passive: true
        });

        this.dashboardService.GetServerCurrentDate(this.EmpId).then(data => {
          const apiDatetimeStr = data.CurrentDate;
          this.apiDatetime = new Date(apiDatetimeStr);
          const apiDatetime = new Date(apiDatetimeStr);
          // const day = apiDatetime.getDate().toString().padStart(2, "0");
          this.serverMonth = (apiDatetime.getMonth() + 1).toString();
          this.serverYear = apiDatetime.getFullYear().toString();
          this.year = this.year ? this.year : this.serverYear;
          this.month = this.month ? this.month : this.serverMonth;

          this.attendanceService.GetAttendanceMonth().then(data => {
            this.attendanceMonths = data;
            this.monthOptions = Object.entries(this.attendanceMonths).map(([key, value]) => ({ value, key }));
          });
          this.attendanceService.GetAttendanceYear().then(data => {
            this.attendanceYears = data;
            this.yearOptions = Object.entries(this.attendanceYears).map(([key, value]) => ({ value, key }));
          });

          this.feedbackservice.GetmyFeedbackData(this.EmpId,this.month,this.year).then(data => {
            this.gridfeedbackData = data.grievanceViewModellist;
          });
        })
        return this.feedbackservice.SendtoEmployeesName()
            .then(data => {
                this.options = data;
            });

            
    }

    calenderMonthConfig(data){
      this.month = parseInt(data.key);
      this.GridData();
    }

    calenderYearConfig(data){
      this.year = data.value.Year;
      this.GridData();
    }

    GridData(){
      this.feedbackservice.GetmyFeedbackData(this.EmpId,this.month,this.year).then(data => {
        this.gridfeedbackData = data.grievanceViewModellist;
      });
    }

    get filteredOptions() {
        this.filtered = this.options.filter(option =>
            option.EmployeeName.toLowerCase().includes(this.searchText.toLowerCase())
        );
        this.CurrentEmployee = this.filtered.filter(option => option.EmployeeID !== parseInt(this.EmpId, 10));
        // Check if dropdownOptions is empty
        if (this.CurrentEmployee.length === 0 && this.searchText.trim().length > 0) {
            const errorInfo = {
                error: {
                    message: "No records found"
                }
            };
            this.searchTextError = [errorInfo];
        } else {
            this.searchTextError = []; // Clear any previous error messages
        }

        return this.CurrentEmployee;
    }

    updateLabels(id) {
        const selectedRadio = document.querySelector('input[name="options"]:checked');
        let selectedOption = selectedRadio.value;
        let formType = selectedOption;
        this.selectedFormType = id;
        if (selectedOption === "1") {
            this.selectedOptionLabel = "feedback";
            this.FeedbackLabel = "Feedback";
            this.GrievanceLabel = "Feedback";
        } else if (selectedOption === "2") {
            this.selectedOptionLabel = "Grievance";
            this.FeedbackLabel = "Grievance";
            this.GrievanceLabel = "Grievance";
        }
    }

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;

    }

    handleClickOutsideDropdown = (event) => {
        const dropdownElement = document.querySelector('.dropdown-menu');
        const buttonElement = document.querySelector('.sendto');
        if (
            dropdownElement &&
            !dropdownElement.contains(event.target) &&
            !buttonElement.contains(event.target)
        ) {
            this.closeDropdown();
        }
    };

    closeDropdown = () => {
        // Method logic here
        this.showDropdown = false;
    };

    selectOption(checked, option) {
        this.selected[option.EmployeeID] = checked;
        this.selectedOptions = this.options.filter(option => this.selected[option.EmployeeID]);

        if (checked) {
            this.SendToEmployeeSelectedIds = option.EmployeeID;
            this.SelectedSendtoEmployeesIDs.push(this.SendToEmployeeSelectedIds);
            this.selectedSentoEmployees.push(option.EmployeeName);
        } else {
            const index = this.SelectedSendtoEmployeesIDs.indexOf(option.EmployeeID);
            if (index > -1) {
                this.SelectedSendtoEmployeesIDs.splice(index, 1);
            }
            const nameIndex = this.selectedSentoEmployees.indexOf(option.EmployeeName);
            if (nameIndex > -1) {
                this.selectedSentoEmployees.splice(nameIndex, 1);
            }
        }
    }

    isSelected(option) {

        return this.selected[option];
    }

    selectAllOptions() {
        // Toggle the selected state for all options
        Object.keys(this.selected).forEach(option => this.selected[option] = this.selectAll);
        // Update the selectedOptions array with the currently selected options
        this.selectedSentoEmployees = this.options.map(option => option.EmployeeName);
        // Toggle the "Select All" button state

        if (this.selectAll) {
            this.selectedSentoEmployees = this.options.map(option => option.EmployeeName);
        } else {
            this.selectedSentoEmployees = [];
        }

        // Update the selectedOptions array with the currently selected options
        this.selectedOptions = this.options.filter(option => this.selected[option.EmployeeID]);
        this.selectAll = !this.selectAll;
    }


    async onEmployeeSelected() {
        this.employeeOrDepartment = '1';
        this.employeeType = 1;
        if (this.searchQuery) {
            this.showEmpDropdown = true;
            try {
                const response = await this.feedbackservice.EmployeeNamesForDropdown(this.searchQuery);
                const data = await response;

                const filterOption = this.searchQuery.toLowerCase();
                this.dropdownOptions = data.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
                // Check if dropdownOptions is empty
                this.empid = this.jwtService.getEmployeeId(); // Assuming this.empid is a string
                this.dropdownOptions = this.dropdownOptions.filter(option => option.EmployeeID !== parseInt(this.empid, 10));
                if (this.dropdownOptions.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "No records found"
                        }
                    };
                    this.searchQueryError = [errorInfo];
                } else {
                    // Clear any previous error messages
                    this.searchQueryError = [];
                }


            } catch (error) {
                //console.error(error);
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
        }
    }

    selectEmployee(EmpOption) {
        const modifiedOption = {
            ...EmpOption,
            ID: EmpOption.EmployeeID
        };

        this.searchQuery = modifiedOption.EmployeeName;

        this.showEmpDropdown = false;
        this.selectedEmployee = modifiedOption;
        return false;
    }

    addToSelectedOptions(id) {
        if (!this.searchQuery) {
            // If searchQuery is empty, show "Please select an employee" error message
            const errorInfo = {
                error: {
                    message: "Please select an employee"
                }
            };
            this.searchQueryError = [errorInfo];
            return;
        }

        this.feedbackservice.GetEmployeesForAdd(id).then(data => {
            const selectedEmployee = this.selectedEmployees.find(employee => employee.EmployeeID === data.EmployeeID);
            if (selectedEmployee) {
                // Employee already exists in selectedEmployees, show "Employee name already exists" error message
                const errorInfo = {
                    error: {
                        message: "Employee name already exists"
                    }
                };
                this.searchQueryError = [errorInfo];
            } else {
                // Employee does not exist in selectedEmployees, add to the list
                this.searchQueryError = [];
                this.Ids = data.EmployeeID;
                this.EmployeeListIDs.push(this.Ids);
                this.selectedEmployees.push(data);
                this.searchQuery = '';
            }
        });
    }

    // GetEmployeeNamesForGrid(id) {
    //     console.log(id);
    //     this.feedbackservice.GetEmployeesForAdd(id)
    //         .then(data => {
    //             this.selectedEmployees.push(data);
    //             console.log(this.selectedEmployees);
    //         });
    // }

    deleteEmployee(emp) {
        const index = this.selectedEmployees.indexOf(emp);
        if (index !== -1) {
            this.selectedEmployees.splice(index, 1);
        }
        const deletedIds = emp.EmployeeID;
        this.EmployeeListIDs = this.EmployeeListIDs.filter(id => id !== deletedIds);

        this.Ids = this.EmployeeListIDs;
    }

    onDepartmentSelected() {
        this.employeeOrDepartment = '2';
        this.employeeType = 2;
        return this.feedbackservice.DepartmentsForDropdown()
            .then(response => {
                this.departments = response;
                return false;
                //console.log(response);
            });
    }

    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };
    }

    openFileDialog() {
        document.getElementById('attachments').click();
    }

    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = ''; // Clear the file input value
        }
    }

    viewFeedback(feedbackData){
      this.dialogService.open({viewModel:Viewfeedback,model:feedbackData});
    }

    reopenFeedback(reopendata){
      this.dialogService.open({viewModel:Reopenmodal,model:reopendata})
      .whenClosed((response) => {
        if(!response.wasCancelled){
          this.successMessage = 'Feedback / Grievance updated successfully.'
          this.GridData();
          this.updateMessages = true;
          setTimeout(() => {this.successMessage = null}, 3000);
        }
      });
    }

    submitForm() {
        event.preventDefault();
        if (this.fileMessage) {
            return false;
        }
        this.errors = null;
        this.controller.validate()
            .then(result => {
                if (result.valid) {
                    const selectedRadio = document.querySelector('input[name="options"]:checked');
                   let selectedOption = selectedRadio.value;
                    if (this.editorContent === '') {
                        // if (selectedOption === "1") {
                            
                        //     this.commentsMessage = "The "+this.FeedbackLabel.replace('F','f')+" field is required.";
                        // } else if (selectedOption === "2") {
                            
                        //     this.commentsMessage = "The "+this.FeedbackLabel.replace('G','g')+" field is required.";
                        // }   
                      this.commentsMessage  ="Feedback/grievance field is required.";                 
                        return false;
                    }
                    let feedbackData = {
                        FeedbackorGrievance: this.title,
                        Comments: this.editorContent,
                        DocumentPath: this.file,
                        DepartmentID: this.selectedDepartment,
                        EmployeeType: this.employeeType,
                        Employeelist: this.EmployeeListIDs,
                        SendToList: this.SelectedSendtoEmployeesIDs,
                        EmployeeEmailIds: this.emails,
                        Type: this.selectedFormType,
                        DocumentName: this.DocumentName,
                        EmployeeID:this.EmpId
                    };
                    console.log(feedbackData);
                    this.isFormSubmitted = true;
                    this.feedbackservice.SaveFeedBackGrievance(feedbackData).then((response) => {
                        this.GrievanceID = response;
                        this.feedbackservice.ViewFeedback(this.GrievanceID)
                            .then(data => {
                                const viewdata = data;
                                this.openDialogBox(viewdata);
                            });
                        this.selectedOptionLabel = "feedback";
                        this.title = '';
                        this.DocumentName = '';
                        this.selectedOptions = [];
                        this.SendToEmployeeSelectedIds = [];
                        this.selectedSentoEmployees = [];
                         this.searchQuery = '';
                        this.selected = {};
                        this.editorContent = '';
                        this.department = '';
                        this.selectedDepartment = null;
                        this.emails = '';
                        this.comment = '';
                        this.SelectedSendtoEmployeesIDs = [];
                        this.EmployeeListIDs = [];
                        const fileInput = document.querySelector('input[type=file]');
                        if (fileInput) {
                            fileInput.value = '';
                        }
                        if (this.editor) {
                            this.editor.setContent('');
                            this.editor = null;
                        }
                        this.editorContent = '';
                        this.selectedEmployees = [];
                        setTimeout(() => {
                            this.message = null
                        }, 3000);
                        this.showPopup = true;
                        this.removeButton = false;
                        this.isFormSubmitted = false;
                    });
                } else {
                    this.errors = "Invalid credentials";
                    const errorElement = document.querySelector(".error-messages");
                    if (errorElement) {
                        errorElement.scrollIntoView({ behavior: "auto"});
                        window.scrollBy(0, -200);
                    }
                }
            })
            .catch(promise => {
                promise.then(err => this.errors = err.errors)
            });
    }

    resetForm(){
        const fileInput = document.querySelector('input[type=file]');
        if (fileInput) {
          fileInput.value = '';
        }
        if (this.editor) {
          this.editor.setContent('');
          this.editor = '';
        }
        this.editorContent = '';
        setTimeout(() => {
          this.message = null
        }, 3000);
        this.controller.reset();
        this.editorContent = '';
        this.selectedEmployees = [];
      this.selectedOptionLabel = '';
      this.title = '';
      this.DocumentName = '';
      this.selectedOptions = [];
      this.SendToEmployeeSelectedIds = [];
      this.selectedSentoEmployees = [];
      this.searchQuery = '';
      this.selected = {};
      this.department = '';
      this.selectedDepartment = null;
      this.emails = '';
      this.removeButton = false;
      this.commentsMessage = '';
        this.SelectedSendtoEmployeesIDs = [];
        this.EmployeeListIDs = [];
        this.comment = '';

    }

    openDialogBox(data) {
        this.dialogService.open({viewModel: SavedDataComponent,model: {viewdata: data},})
        .whenClosed((response) => {
          if(!response.wasCancelled){
            this.GridData();
          }
        });
    }

    detached() {
      tinymce.remove(this.editor);
      document.removeEventListener('click', this.handleClickOutsideDropdown);
    }

    cancle() {
        this.router.navigateToRoute('login');
    }

    addToRecentItems(){
        this.leavemanagementService.AddToRecentItems(this.EmpId,this.siteMapId).then(data=>
         {

         });
     }
}
