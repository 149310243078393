import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import {SharedState} from '../../shared/state/sharedstate';
import {config} from '../../shared/services/config';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {ApiService} from '../../shared/services/apiservice';
import { EmployeeMasterService } from '../../shared/services/employeemasterservice';
import { JwtService } from '../../shared/services/jwtservice';
import {DialogService} from 'aurelia-dialog';
import { DeactivateAlertComponent } from '../alertpopup/deactivatealertcomponent';
import { writeFile, utils } from 'xlsx';
import * as XLSX from 'xlsx';
import { ActivateAlertComponent } from '../alertpopup/activatealertcomponent';
import { LeavemanagementService } from '../../shared/services/leavemanagementservice';
import { constants } from '../../shared/services/constants';
import {LoginService} from "../../shared/services/loginservice";
import {App} from "../../app";


@inject(EmployeeMasterService,ApiService,LeavemanagementService,SharedState,JwtService,DialogService, Router, App, HttpClient,ValidationControllerFactory,LoginService, Element )

export class EmployeeMasterComponent {


    selectedEmployees = [];
    showEmpDropdown = true;
    selectedEmployeeName = '';
    GetAllEmployees=[];
    pageSize = config.grid_PazeSize;
    selectedEmployee = null;
    selectedEmployeeName = null;
    filteredEmployees = [];
    selectedStatus = 'ACT';
    employees = [];
    isLoading = false;
    FavoritesExist = false;
    sitemapId=constants.employeemastersitemapid;
    routerView = "main-content";

   constructor( employeemasterService,apiService,LeavemanagementService,sharedState,JwtService,DialogService, router, App, httpClient, loginservice,element) {
        this.employeemasterService=employeemasterService;
        this.apiService = apiService;
        this.leavemanagementService = LeavemanagementService;
        this.sharedState = sharedState;
        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.router = router;
        this.httpClient = httpClient;
        this.element = element;
        this.searchQuery = '';
        this.dropdownOptions = [];
        this.selectedEmployees = [];
        this.selectedEmployee = null;
        this.filterEmpOption = '';
        this.loginservice = loginservice;
        this.app = App;
        this.empid = this.jwtService.getEmployeeId();
   }

    filters = [{value: '', keys: [ 'EmployeeCode','EmployeeName','UserName','ReportingManager','EmployeeClassText','DepartmentName','PositionPositionLevelName' ]},];
   attached()
   {
       // if(this.jwtService.getToken() === undefined){
       //     this.loginservice.purgeAuth();
       //     this.router.navigateToRoute('login')
       // }
        this.app.updatePropertyValue(false);
        document.querySelector('router-view').classList.remove(this.routerView);
        this.isLoading = true;
        this.employeemasterService.GetAllEmployeeDetails('ACT',this.empid)
        .then(GetAllEmployees =>{
        this.GetAllEmployees = GetAllEmployees;  
        this.isLoading = false; 
       
        });

        this.leavemanagementService.GetPersonFavoriteItems(this.empid,191)
        .then(data=>{
            this.FavoritesExist=data;
            console.log(data);
        });
        this.addToRecentItems();
          
   }

  displayEmployees() {
    this.selectedEmployee = null;
    this.isLoading = true;
    
    if (this.selectedStatus === 'All') {
      this.employeemasterService.GetAllEmployeeDetails('',this.empid)
        .then(GetAllEmployees => {
          this.GetAllEmployees = GetAllEmployees;
          this.isLoading = false;
        });
  
    } else if (this.selectedStatus === 'ACT') {
      this.employeemasterService.GetAllEmployeeDetails('ACT',this.empid)
        .then(GetAllEmployees => {
          this.GetAllEmployees = GetAllEmployees;
          this.isLoading = false;
        });
  
    } else if (this.selectedStatus === 'INA') {
      this.employeemasterService.GetAllEmployeeDetails('INA',this.empid)
        .then(GetAllEmployees => {
          this.GetAllEmployees = GetAllEmployees;
          this.isLoading = false;
        });
    }
   
  }
  
  edit(EmployeeID) {
     
    // Redirect to another screen
     this.router.navigateToRoute('myprofile',{ id: EmployeeID});
     
  }

  toggleTaskDropdown($event, employee) {
    const isDropdownOpen = employee.showDropdown;
    this.filteredEmployees.forEach((item) => {
      item.showDropdown = false;
    });
    if (!isDropdownOpen) {
      employee.showDropdown = true;
      document.addEventListener('click', this.closeDropdown);
    }
    $event.stopPropagation();
      setTimeout(()=>{
      var dropdowns = document.getElementsByClassName("dropdown");
      for (var i = 0; i < dropdowns.length; i++) {
        var dropdown = dropdowns[i];
        var rect = dropdown.getBoundingClientRect();
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        if (rect.bottom > windowHeight) {
          dropdown.classList.add("upwards");
        } else{
            dropdown.classList.remove("upwards");
        }
      }
    },50)
  }

  toggleViewDropdown($event, employee) {
    const isDropdownOpen1 = employee.showDropdown1;
    this.filteredEmployees.forEach((item) => {
      item.showDropdown1 = false;
    });
    if (!isDropdownOpen1) {
      employee.showDropdown1 = true;
      document.addEventListener('click', this.closeDropdown1);
    }
    $event.stopPropagation();
    setTimeout(()=>{
      var dropdowns = document.getElementsByClassName("dropdown");
      for (var i = 0; i < dropdowns.length; i++) {
        var dropdown = dropdowns[i];
        var rect = dropdown.getBoundingClientRect();
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        if (rect.bottom > windowHeight) {
          dropdown.classList.add("upwards");
        } else{
            dropdown.classList.remove("upwards");
        }
      }
    },50)
  }

  empDetails(EmployeeID){
    // View Employee Details
    this.router.navigateToRoute('myprofile',{ id: EmployeeID});
  }

async deactivatePerson(PersonID) {
    
    const result = await this.dialogService.open({
       viewModel: DeactivateAlertComponent,
       model:"",
    }).whenClosed(response => response);

    if (result.wasCancelled) {
       return;
    }
    await this.employeemasterService.DeletePersonDetails(PersonID, this.empid);
    this.Deactivatemsg = "Employee deactivated successfully";
    this.DeactivateDeletmessage = true; 
    
    this.isLoading = false; 
    window.location.reload();
 }

async activatePerson(PersonID) {
  
    const result = await this.dialogService.open({
      viewModel: ActivateAlertComponent,
      model:"",
    }).whenClosed(response => response);

    if (result.wasCancelled) {
      return;
    }
    await this.employeemasterService.ActivatePersonDetails(PersonID, this.empid);
  // this.Deactivatemsg = "Employee deactivated successfully";
  // this.DeactivateDeletmessage = true;  
    window.location.reload();
}

//Export to Excel

exportData() {

      const data = this.GetAllEmployees;     
      const filename = 'employeedetails';
      this.exportToExcel(data, filename);               
      
}

exportToExcel(data, filename) {

    const requiredColumns = ['EmployeeCode', 'EmployeeName', 'ReportingManager','UserName', 'EmployeeClass', 'DepartmentName', 'PositionLevel'];
    
    const filteredData = data.map(employee => {
      return {
        Employeecode: employee.EmployeeCode,
        Employeename: employee.EmployeeName,
        ReportingManager : employee.ReportingManager,
        Username: employee.UserName,
        Employeeclass: employee.EmployeeClassText,
        Departmentname:employee.DepartmentName,
        Positionlevel:employee.PositionPositionLevelName
      };
    });
    
    // const worksheet = XLSX.utils.json_to_sheet(data); // show all columns in Excel sheet.
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }

  addToFavourites(){    
    // this.sitemapId=191; 
      
     this.leavemanagementService.AddToFavourites(this.empid,this.sitemapId).then(data=>
      {
          console.log(data);  
          location.reload();
      });
     
  }
  addToRecentItems(){    
    this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapId).then(data=>
     {
      // window.location.reload();

     });
 }

}
