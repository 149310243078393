import {inject } from 'aurelia-framework';
import {DialogController} from "aurelia-dialog";
import {Router} from 'aurelia-router';
import {LoginService} from "../../../shared/services/loginservice";
import {JwtService} from "../../../shared/services/jwtservice";
import { FeedbackService } from '../../../shared/services/feedbackservice';
import { config } from '../../../shared/services/config';

@inject(DialogController,LoginService,JwtService,Router,FeedbackService)
export class Viewfeedback {
    isDragging = false;
    dialog;
    URL= config.img_url;
    static inject = [Element];
    pageSize = config.grid_PazeSize;
  constructor(controller,loginservice,jwtService,router,FeedbackService) {
    this.controller = controller;
      this.router = router;
      this.loginservice = loginservice;
      this.jwtService = jwtService;
      this.feedbackService = FeedbackService;

      this.EmpId = this.jwtService.getEmployeeId();
  }

  activate(feedbackData) {
    this.FeedData = feedbackData;
    this.grievanceId = feedbackData.GrievanceID;
  }
    attached(){
        this.isLoading = true;
        this.feedbackService.ViewmyFeedbackData(this.EmpId,this.grievanceId).then(data => {
          this.viewData = data;
          this.Files = this.viewData.DocumentPath;
          this.feedbackhistory = this.viewData.historylist;
        })
    }
  cancle() {
    this.controller.cancel();
}
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }

    downloadFile() {
      const downloadUrl = this.URL+ this.Files;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.download = downloadUrl;
      link.click();
    }

    HistorydownloadFile(files) {
      const downloadUrl = this.URL+ files;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.download = downloadUrl;
      link.click();
    }

    cancel(){
      this.controller.cancel();
  }
}
