import {inject } from 'aurelia-framework';
import { FeedbackService } from '../../../shared/services/feedbackservice';
import {Router} from 'aurelia-router';
import {App} from "../../../app";
import {DialogController} from "aurelia-dialog";
import {config} from "../../../shared/services/config";
import {LoginService} from "../../../shared/services/loginservice";
import {JwtService} from "../../../shared/services/jwtservice";

@inject(DialogController,FeedbackService, Router, App,LoginService,JwtService)
export class SavedDataComponent {
    SendToList = "";
    URL=config.img_url;
    isDragging = false;
    dialog;
    static inject = [Element];
    constructor(controller,feedbackservice,router,App,loginservice,jwtService) {

        this.controller = controller;
        this.feedbackservice = feedbackservice;
        this.router = router;
        this.app = App;
        this.router = router;
        this.loginservice = loginservice;
        this.jwtService = jwtService;
    }
    activate(model) {
        const viewdata = model.viewdata;
        this.TypeName = viewdata.TypeName;
        this.RaisedBy = viewdata.RaisedBy;
        this.FileName = viewdata.DocumentName;
        this.EmployeeEmailIds = viewdata.EmployeeEmailIds;
        this.EmployeeNames = viewdata.EmployeeNames;
        this.EmployeeName =viewdata.EmployeeName;
        this.StatusText = viewdata.StatusText;
        // Check if viewdata.EmployeeNames is a string and remove the leading comma if present
        if (typeof viewdata.EmployeeNames === 'string') {
            this.EmployeeNames = viewdata.EmployeeNames.replace(/^,\s*/, '');
        } else {
            this.EmployeeNames = viewdata.EmployeeNames;
        }
        this.EmployeeType = viewdata.EmployeeType;
        this.Employeelist = viewdata.Employeelist;
        this.FeedbackorGrievance = viewdata.FeedbackorGrievance;
        this.SendToList = viewdata.SendToList;
        // Check if viewdata.SendToNames is a string and remove the leading comma if present
        if (typeof viewdata.SendToNames === 'string') {
            this.SendToNames = viewdata.SendToNames.replace(/^,\s*/, '');
        } else {
            this.SendToNames = viewdata.SendToNames;
        }
        this.Comments = viewdata.Comments;
        // Remove HTML tags from the Comments string
        if (typeof viewdata.Comments === 'string') {
            const tempElement = document.createElement('div');
            tempElement.innerHTML = viewdata.Comments;
            this.Comments = tempElement.textContent || tempElement.innerText;
        } else {
            this.Comments = viewdata.Comments;
        }
        this.DepartmentName = viewdata.DepartmentName;
        this.Files = viewdata.DocumentPath;
        if (viewdata.DocumentPath) {
            this.file = viewdata.DocumentPath;
            this.DocumentName = viewdata.DocumentPath; // Set the desired file name
        }

    }
    attached(){
        this.isLoading = true;
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
    }

    downloadFile() {
        const downloadUrl = this.URL + this.Files;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.download = downloadUrl;
        link.click();
    }
    cancle() {
        this.controller.ok(true);
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }

}
