import {inject} from 'aurelia-dependency-injection';
import { EmployeeService } from '../../shared/services/employeeservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {JwtService} from '../../shared/services/jwtservice';
import {Router} from 'aurelia-router';
import { SalaryRevisionService } from '../../shared/services/salaryrevisionservice';
import flatpickr from "flatpickr";
import {LeavemanagementService} from "../../shared/services/leavemanagementservice";
import { Constants } from '../../../node_modules/msal/lib-commonjs/index';
import { constants } from '../../shared/services/constants';
import { config } from '../../shared/services/config';
import * as XLSX from 'xlsx';


@inject(SalaryRevisionService,EmployeeService,LeavemanagementService,JwtService,ValidationControllerFactory,JwtService,Router)
export class EmployeeSalaryRevisionComponent {
    
    EmployeeSalaryRevisionList=[];
    FromDate='2023-12-16';//'2023-12-11';
    ToDate='2024-02-16';
    isDropdownOpen = false;
    selectedStatus = 'ACT';
    defaultToDate;
    defaultFromDate;  
    showEmployeeDropdown = false;
    FavoritesExist = false;
    sitemapId=constants.employeesalaryrevisionlistsitemapid;
    pageSize=config.grid_PazeSize;


    statusOptions = [
        { label: 'All', value: 'All', checked: false },
        { label: 'Pending submission', value: 'ACT', checked: true },
        { label: 'Pending approval', value: 'PEA', checked: true },
        { label: 'Need clarification', value: 'NED', checked: false },
        { label: 'Approved', value: 'APP', checked: false }
    ];
    

    constructor(salaryrevisionService,LeavemanagementService,JwtService,controllerFactory,jwtService,router){
    
   // this.controller = controllerFactory.createForCurrentScope();
    this.jwtService = jwtService;
    this.router = router;
    this.salaryrevisionService=salaryrevisionService;
    this.leavemanagementService = LeavemanagementService;
    this.jwtService= JwtService;
   // this.empid = this.jwtService.getEmployeeId();
    }
        attached() {
            this.LoginempId=window.localStorage['EmployeeID'];

            const fromDate = new Date();
            fromDate.setMonth(fromDate.getMonth() -4);
            this.FDate = fromDate.toLocaleDateString().replace(/\//g, '-');
            this.From =  this.FDate;
            const toDate = new Date();
            toDate.setMonth(toDate.getMonth() + 6);
            this.TDate = toDate.toLocaleDateString().replace(/\//g, '-');
            this.To= this.TDate;
            flatpickr(this.FromdatePicker, {
                closeOnSelect: true,
                dateFormat: 'd-m-Y',
                minDate: null,
                maxDate: null,
                defaultDate: fromDate,
                enableTime: false,
                enableSeconds: false,
                time_24hr: false,
                minuteIncrement: 1,
                hourIncrement: 1,
                timeFormat: 'h:i K',
                clickOpens:false,
                onClose: function (selectedDates, dateStr, instance) {
                    this.FromDate = dateStr;
                }
            });
            this.FromcalendarIcon.addEventListener('click', () => {
                this.FromdatePicker._flatpickr.open();
            });
            flatpickr(this.TodatePicker, {
                closeOnSelect: true,
                dateFormat: 'd-m-Y',
                minDate: null,
                maxDate: null,
                defaultDate: toDate,
                enableTime: false,
                enableSeconds: false,
                time_24hr: false,
                minuteIncrement: 1,
                hourIncrement: 1,
                timeFormat: 'h:i K',
                clickOpens:false,
                onClose: function (selectedDates, dateStr, instance) {
                    this.default = dateStr;
                }
            });
            this.TocalendarIcon.addEventListener('click', () => {
                this.TodatePicker._flatpickr.open();
            });

            this.salaryrevisionService.DepartmentDropdown(this.LoginempId, 'ACT')
            .then(data => {
                this.departments = data;
                console.log(this.departments);
            });

            this.salaryrevisionService.getEmployeeSalaryRevision(this.FromDate,this.ToDate,'ACT',this.LoginempId)
                .then(data =>{
                this.EmployeeSalaryRevisionList = data; 
                console.log(data);        
                });

                this.salaryrevisionService.GetPersonFavoriteItems(this.LoginempId,this.sitemapId)
                .then(data=>{
                    this.FavoritesExist=data;
                });
        }

        filters = [
            {value: '', keys: ['EmployeeName','PositionlevelName','RevisionPeriod','NextRevisionDueDate']},
        ];

        addToFavourites(){
          
            this.salaryrevisionService.AddToFavourites(this.LoginempId,this.sitemapId).then(data=>
            {
                this.FavoritesExist=data;
                 window.location.reload();
    
            });
        }

        filterClick() {
            event.preventDefault();
            const clickedDiv = event.target.parentNode;
            clickedDiv.classList.toggle('filter-open');
            const divs = document.querySelectorAll('.filter-open');
            divs.forEach((div) => {
                if (div !== clickedDiv) {
                    div.classList.remove('filter-open');
                }
            });
            const handleOutsideClick = (event) => {
                if (!clickedDiv.contains(event.target)) {
                    divs.forEach((div) => {
                        div.classList.remove('filter-open');
                    });
                    document.removeEventListener('click', handleOutsideClick);
                }
            };
            if (clickedDiv.classList.contains('filter-open')) {
                document.addEventListener('click', handleOutsideClick);
            } else {
                document.removeEventListener('click', handleOutsideClick);
            }
        }

        async displayEmployeess(event) {
            this.showEmployeeDropdown = true;
            const statusVal = event.target.value;
            this.salaryrevisionService.DepartmentDropdown(this.LoginempId, statusVal)
                .then(data => {
                    this.departments = data;
                    console.log(this.departments);
                });
           
          }

          selectEmployees (empOptions) {
            const modifiedOption = { ...empOptions, ID: empOptions.EmployeeID };
    
            this.eName = modifiedOption.EmployeeName;
            this.eNameID = modifiedOption.EmployeeID;
            this.showEmployeeDropdown = false;
            this.selectedEmployee = modifiedOption;
            const finalToDate = this.ToDate ? this.ToDate : this.defaultToDate;
            const finalFromDate = this.FromDate ? this.FromDate : this.defaultFromDate;
            const timeoff = {
                Fromdate: finalFromDate,
                Todate: finalToDate,
                EmployeeId: this.eNameID,
            };
            //console.log(timeoff);
            // this.isLoading = true;
            // this.leavemanagementService.GetEmployeeTimeOffRequests(timeoff).then(data=> {
            //     this.EmployeeTimeOffRequests = data.List;
            //     this.isLoading = false;
            //     //console.log(timeoff);
            // });
    
        }

          calculateDateDifference() {
            const filterOpenElements = document.querySelectorAll('.filter-open');
            filterOpenElements.forEach(element => element.classList.remove('filter-open'));
            // const selectedStatusLists = this.selectedStatuses.map(status => status.value);
            const selectedStatusList = this.statusOptions
                .filter(status => status.checked)
                .map(status => status.value);
    
            const finalToDate = this.ToDate ? this.ToDate : this.defaultToDate;
            const finalFromDate = this.FromDate ? this.FromDate : this.defaultFromDate;
            console.log(finalToDate);
            const selectedRadio = document.querySelector('input[name="employees"]:checked');
            this.selectedValue = selectedRadio ? selectedRadio.value : '';
            const listData = {
                Fromdate: finalFromDate,
                Todate: finalToDate,
                EmployeeStatus: this.selectedValue,
                StatusList: selectedStatusList.includes('All') ? ['All'] : selectedStatusList.filter(status => status !== 'All'),
                DepartmentID: this.department,
               // EmployeeClassID: this.employeeclass,
                LoggedInEmployeeId : this.LoginempId,
            };
            console.log(listData);
            this.isLoading = true;
    
            // this.leavemanagementService.GetEmployeeTimeOffRequests(timeoff)
            //     .then(data => {
            //         this.EmployeeTimeOffRequests = data.List;
            //         this.isLoading = false;
            //         //console.log(timeoff.EmployeeName);
            //         console.log(this.EmployeeTimeOffRequests);
            //     });

            this.salaryrevisionService.getEmployeeSalaryRevision(this.FromDate,this.ToDate,'ACT',this.LoginempId)
            .then(data =>{
            this.EmployeeSalaryRevisionList = data; 
            console.log(data);        
            console.log(listData);    
            });
        }

        selectAllChanged(event) {
            const isChecked = this.statusOptions[0].checked;
            this.statusOptions.forEach((status, index) => {
                if (index !== 0) {
                    status.checked = isChecked;
                }
            });
        }

        handleCheckboxChange(event, status) {
            if (status.value === 'All') {
                this.statusOptions.forEach((statusOption, index) => {
                    if (index !== 0) {
                        statusOption.checked = false;
                    }
                });
            } else {
                const allChecked = this.statusOptions.slice(1).every(statusOption => statusOption.checked);
                this.statusOptions[0].checked = allChecked;
            }
    
            //status.checked = event.target.checked;
        }
          
        resetDepartmentDropdown() {
            this.department = ''; // Reset the selected department to empty value
        }

        resetStatusDropdown() {
            this.statusOptions.forEach((status, index) => {
                if (index === 1 || index === 2) {
                    status.checked = true; // Select 'Pending approval' and 'Pending with me'
                } else {
                    status.checked = false; // Deselect other statuses
                }
            });
    
            // Update the selectedStatusList if needed
            this.selectedStatusList = this.statusOptions.filter(status => status.checked);
    
            //console.log(this.selectedStatusList);
        }

        exportData() {
            const data = this.EmployeeSalaryRevisionList;
            const filename = 'EmployeeSalaryRevision';
            this.exportToExcel(data, filename);
        }
        exportToExcel(data, filename) {
            const headers = {
                Employeename: 'Employee name',
                Department: 'Department',
                Position: 'Position',
                Revisionperiod: 'Revision period',
                Revisionduedate: 'Revision due date',
                Status: 'Status',             
            };
            const filteredData = data.map(revision => {
                return {
                    Employeename:revision.EmployeeName,
                    Department:revision.DepartmentName,
                    Position: revision.PositionlevelName,
                    Revisionperiod:revision.RevisionPeriod,
                    Revisionduedate:revision.NextRevisionDueDate,
                    Status:revision.StatusCode,            
                    
                };
            });
            const headerRow = Object.values(headers);
            const dataRows = filteredData.map(leave => Object.values(leave));
            const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
            XLSX.writeFile(workbook, `${filename}.xlsx`);
        }

        resetPage(){
            this.attached();
            this.selectedStatus='ACT';
           }

          
  }
