import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import {EmployeeService} from '../../shared/services/employeeservice';
import {SharedState} from '../../shared/state/sharedstate';
import {config} from '../../shared/services/config';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";


@inject(EmployeeService, SharedState, Router, HttpClient,LoginService,JwtService)
export class EmployeeMaster {
  
    constructor(employeeService, sharedState, httpClient,router,loginservice,jwtService) {
        this.employeeService = employeeService;
        this.sharedState = sharedState;
        this.router = router;
        this.httpClient = httpClient;
        this.router = router;
        this.loginservice = loginservice;
        this.jwtService = jwtService;
       
      }


      employeedetails = [];
      pageSize = 10;
  
      filters = [ {value: '', keys: ['UserName']},];

    attached(){
        this.isLoading = true;
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        // setTimeout(() => {
        // this.isLoading = false;
        // }, 3000);
        this.employeeService.GetAllEmployeeDetails()
        .then(data=>{
        this.employeedetails = data;
        this.isLoading = false;
      });
      setTimeout(()=>{
        Scrollbar.init(document.querySelector('.smooth-scrollbar'));
      },4000);
    }
}

