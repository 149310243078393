import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { JwtService } from '../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import * as tinymce from 'tinymce';
import { FeedbackService } from '../../../shared/services/feedbackservice';

@inject(DialogController,JwtService,ValidationControllerFactory,FeedbackService)
export class Reopenmodal {

  constructor(DialogController,JwtService,controllerFactory,FeedbackService) {
    this.jwtService = JwtService;
    this.controller = DialogController;
    this.feedbackService = FeedbackService;
    this.controllerFactory = controllerFactory.createForCurrentScope();

    this.Employeeid = this.jwtService.getEmployeeId();
  }

  activate(viewreopendata) {
    this.grievanceID = viewreopendata.GrievanceID
    this.feedback = viewreopendata.FeedbackorGrievance;
  }

  async attached() {
    
  //   tinymce.init({
  //     selector: '#content',
  //     toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
  //     setup: editor => {
  //       editor.on('init', () => {
  //           this.editor = editor;
  //           this.editorContent = '';
  //       });
  //       editor.on('change', () => {
  //           this.editorContent = editor.getContent();
  //       });
  //     },
  // });

    this.feedbackService.ViewmyFeedbackData(this.Employeeid,this.grievanceID).then(data => {
      this.status = data.StatusText;
      tinymce.init({
        selector: '#content',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        setup: editor => {
          editor.on('init', () => {
              this.editor = editor;
              this.editorContent = '';
          });
          editor.on('change', () => {
              this.editorContent = editor.getContent();
          });
        },
    });
    })
    }


    reopenSubmit(){
      const reopenReqData = {
        GrievanceID : this.grievanceID,
        Comments : this.editorContent,
        EmployeeID : this.Employeeid,
      }
      this.feedbackService.PostReopenData(reopenReqData).then(() =>{
        this.controller.ok(true);
      })
    }

    cancel() {
      this.controller.cancel();
   }
}
